<template>
  <component :is="commonComponent" id="terms" classs="terms ">
    <section id="terms-content" class="pc_padding">
      <div class="common-content-row">
        Welcome to <strong>{{ domainConfig["IAmURL"] }}</strong>
      </div>

      <div class="common-content-row">
        Thank you for visiting <strong>{{ domainConfig["IAmURL"] }}</strong> (hereinafter referred
        to as &quot;this Website&quot;). By using this Website, you agree to comply with and be
        bound by the following Terms of Service. Please read these terms carefully; if you do not
        agree, please do not use this Website.
      </div>

      <div class="common-content-head">
        Terms of Use
      </div>

      <div class="common-content-row">
        1.1 - You must be at least 18 years old or use this Website under the supervision of a
        parent or guardian.
      </div>

      <div class="common-content-row">
        1.2 - You agree not to use this Website for any illegal or unauthorized activities.
      </div>

      <div class="common-content-row">
        1.3 - You may not interfere with or disrupt the normal functioning of this Website.
      </div>

      <div class="common-content-head">
        Intellectual Property
      </div>

      <div class="common-content-row">
        2.1 - All content and code on our website are original, and we strictly prohibit any form of
        infringement.
      </div>

      <div class="common-content-row">
        2.2 - You may not reproduce, distribute, or otherwise use any content on this Website
        without our explicit written permission.
      </div>

      <div class="common-content-head">
        User-Generated Content
      </div>

      <div class="common-content-row">
        3.1 - Any content you post on this Website will be considered non-confidential and
        non-proprietary.
      </div>

      <div class="common-content-row">
        3.2 - You grant this Website a global, perpetual, irrevocable, royalty-free license to use,
        copy, modify, publish, and distribute any content you provide.
      </div>

      <div class="common-content-head">
        Disclaimer
      </div>

      <div class="common-content-row">
        4.1 - The information on this Website is created based on a broad range of authoritative
        sources, including academic research, official reports, industry publications, and expert
        insights. While we strive for originality and accuracy, the content is for reference
        purposes only and does not constitute professional advice.
      </div>

      <div class="common-content-row">
        4.2 - This Website does not guarantee the absolute accuracy, completeness, or timeliness of
        the information provided. We are not liable for any loss or damage resulting from reliance
        on this content. Users are encouraged to verify information from additional trusted sources
        before making decisions based on it.
      </div>

      <div class="common-content-head">
        Termination
      </div>

      <div class="common-content-row">
        5.1 - We reserve the right to terminate or restrict your access to this Website at any time,
        without prior notice.
      </div>

      <div class="common-content-head">
        Modification of Terms
      </div>

      <div class="common-content-row">
        6.1 - We reserve the right to modify these Terms of Service at any time. Revised terms will
        be posted on this Website and take effect immediately.
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: `Terms of Services | ${this.domainConfig['meta'].title}`,
      meta: [
        {
          name: 'description',
          content: `${this.domainConfig['meta'].des}`
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "domainConfig"
    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>